import { ExpectedValueDiv, ValuesDiv, HelperTextDiv } from "styles/ReportsStyle"
import { CustomSlider } from "../../Components/CustomSlider/CustomSlider"
import { Info } from "@mui/icons-material"
import { Value, Expected } from "variables/sharedVariables"
import { FactoryResult, Status, WellknownMetadata } from "utility/factoryResultTypes"
import { formatNotification } from "utility/helpingFunctions"

export interface ResultDetailsProps {
  result: FactoryResult
}

const parseFloatOrNull = (str: string | undefined): number | null => {
  const ret = parseFloat(str as string)
  if (isNaN(ret)) {
    return null
  }
  return ret
}

const causesOrNull = (str: string | undefined): Array<string> | null => {
  if (!str) {
    return null
  }
  try {
    return JSON.parse(str)
  } catch {
    return [str]
  }
}

export const ResultDetails = ({ result }: ResultDetailsProps) => {
  const meta = result.reason.metadata as WellknownMetadata
  const value = parseFloatOrNull(meta.value)
  const lower = parseFloatOrNull(meta.lower)
  const upper = parseFloatOrNull(meta.upper)
  const causes = causesOrNull(meta.possible_causes)

  const showSlider = value && (lower || upper)
  const showExpected = value && meta.expected_value && meta.expected_value.length > 0

  // For failed results which are leaf nodes (no children) where don't display the slider or
  // expected value, we show the formatted reason text.
  const showReasonText =
    !showSlider && !showExpected && result.status === Status.Fail && result.children.length == 0

  return (
    <>
      {(showSlider || showExpected) && (
        <ValuesDiv>
          {showSlider && <CustomSlider value={value} min={lower} max={upper} />}
          {showExpected && (
            <ExpectedValueDiv>
              <span style={{ fontWeight: 500 }}>
                {Value}: {value}
              </span>
              <span>
                {Expected}: {meta.expected_value}
              </span>
            </ExpectedValueDiv>
          )}
        </ValuesDiv>
      )}
      {causes &&
        causes.map((cause) => (
          <HelperTextDiv key={cause}>
            <Info style={{ color: "lightgray" }} />
            <span style={{ fontWeight: "bold" }}>{cause}</span>
          </HelperTextDiv>
        ))}
      {showReasonText && <ValuesDiv>{formatNotification(result.reason)}</ValuesDiv>}
    </>
  )
}
