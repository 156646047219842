import { CreateAxiosConfig } from "pages/AxiosConfig"
import { useState } from "react"
import { logOut } from "./user_authentication"
import { useRedirect } from "react-admin"
import axios, { AxiosError, AxiosResponse } from "axios"
import { DeviceInfo, EState } from "utility/types"
import { FactoryResultWithMetadata } from "./factoryResultTypes"

export const useReportHook = () => {
  const axiosAlphasenseFactory = CreateAxiosConfig("/api/v1")
  const [open, setOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState<string | null>(null)
  const [reportDetails, setReportDetails] = useState<FactoryResultWithMetadata | undefined>()
  const redirect = useRedirect()

  const stateToReportUrlComponent = (state: EState) => {
    switch (state) {
      case EState.CONFIGURATION:
        return "configuration"
      case EState.CALIBRATION:
        return "calibration"
      case EState.ACCEPTANCE:
        return "quality_assurance"
      default:
        throw Error(`Unknown state: ${state}`)
    }
  }

  const getParsedReport = (device: DeviceInfo, state: EState) => {
    axiosAlphasenseFactory
      .get(`/devices/${device.hostname}/reports/${stateToReportUrlComponent(state)}`)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          setOpen(true)
          setReportDetails(response.data)
        } else {
          setAlertMessage(`Server responded with ${response.status} ${response.data}`)
        }
      })
      .catch((e: Error | AxiosError) => {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 401 || e.response?.status === 403) {
            logOut(redirect)
          } else if (e.response?.status === 404) {
            setAlertMessage("no report found")
          } else {
            console.error("Failed to get report data", e)
          }
        } else {
          console.error("Unexpected error:", e)
        }
      })
  }
  return {
    open,
    setOpen,
    alertMessage,
    setAlertMessage,
    reportDetails,
    getParsedReport,
  }
}
