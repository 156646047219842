import { Container, MainContainer, StyledGridItem } from "../../styles/LoginStyle/LoginStyles"
import { LogoContent } from "../Login/LogoContent"
import { ChangePasswordForm } from "./ChangePasswordForm"

export const ChangePasswordPage = () => {
  return (
    <MainContainer>
      <Container>
        <LogoContent />
        <StyledGridItem item xs={6}>
          <ChangePasswordForm />
        </StyledGridItem>
      </Container>
    </MainContainer>
  )
}
