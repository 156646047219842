import {
  MainContainer,
  Container,
  StyledGridItemLoginForm,
} from "../../styles/LoginStyle/LoginStyles"
import { LoginForm } from "./LoginForm"
import { LogoContent } from "./LogoContent"

export const LoginPage = () => {
  return (
    <MainContainer>
      <Container>
        <LogoContent />
        <StyledGridItemLoginForm item xs={6}>
          <LoginForm />
        </StyledGridItemLoginForm>
      </Container>
    </MainContainer>
  )
}
