import { Stepper, Step, StepLabel } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { translateState } from "utility/helpingFunctions"
import { EState } from "utility/types"

const useStyles: any = makeStyles(() => ({
  root: {
    "& .MuiStepLabel-root .Mui-active": { color: "#2F4B8A" },
    "& .MuiStepLabel-root .Mui-completed": { color: "#207b18" },
    "& .MuiStepLabel-root .Mui-error": { color: "#AF1A1A" },
    "& .MuiStepLabel-root .Mui-disabled .MuiStepIcon-root": { color: "rgba(120, 120, 120, 0.6)" },
    "& .MuiSvgIcon-root": { width: "40px", height: "40px", margin: "-20% -10% -20% 0" },
  },
  disabledStepLabel: {
    color: "gray",
  },
}))
interface StepsProp {
  label: string
  description?: string
}
interface ProgressBarRequiredProps {
  state: string
  steps: StepsProp[]
}
interface ProgressBarOptionalProps {
  isStateFailed: boolean
  ifBootstrapPage?: boolean
  isDeviceActive?: boolean
  isPinned?: boolean
  assuranceStateCompleted?: boolean
}
interface ProgressBarProps extends ProgressBarRequiredProps, ProgressBarOptionalProps {}

export const ProgressBar = ({
  state,
  isStateFailed,
  steps,
  isDeviceActive,
  ifBootstrapPage,
  isPinned,
}: ProgressBarProps) => {
  const rootStyle = useStyles()

  const find_index_of_step = (label: string) =>
    [...steps.map((step) => step.label), EState.COMPLETED].findIndex((step) => step === label)
  const find_index_of_failed_state = (step: number) =>
    step === find_index_of_step(translateState(state))

  const isDisabled = !isDeviceActive && ifBootstrapPage && !isPinned && state !== EState.COMPLETED

  return (
    <>
      <Stepper
        className={rootStyle.root}
        activeStep={find_index_of_step(translateState(state))}
        alternativeLabel
        style={{ background: "none", width: "100%", marginBottom: "10px" }}
      >
        {steps.map((step: StepsProp, index: number) => {
          return (
            <Step key={index} completed={index < find_index_of_step(translateState(state))}>
              <StepLabel
                className={isDisabled ? rootStyle.disabledStepLabel : ""}
                error={isStateFailed ? find_index_of_failed_state(index) : false}
              >
                {translateState(step.label)}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </>
  )
}
