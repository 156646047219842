import { SubContainer } from "styles/CommonStyles"
import { Title } from "styles/FlashStationStyle/ReadyToFlashStyle"
import { SessionType } from "utility/types"
import { useDevicesWithTransitions } from "utility/useDevicesWithTransitions"
import { BootstrappingDataProps } from "./InProgressSession"
import { Pinned } from "variables/sharedVariables"

export const PinnedSession = ({
  bootstrappingDevices,
  activeFilters,
  pinnedIds,
  setPinnedIds,
  pinnedDevicesUpdateTime,
  setPinnedDevicesUpdateTime,
  onChangeRobotIdentity,
}: BootstrappingDataProps) => {
  const session: SessionType = SessionType.pinnedSession
  const devicesWithTransitions = useDevicesWithTransitions(
    bootstrappingDevices,
    true,
    session,
    activeFilters,
    pinnedIds,
    setPinnedIds,
    pinnedDevicesUpdateTime,
    setPinnedDevicesUpdateTime,
    onChangeRobotIdentity,
  )

  return (
    <SubContainer>
      <Title>{Pinned}</Title>
      <div>{devicesWithTransitions}</div>
    </SubContainer>
  )
}
