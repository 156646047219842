import { Box, Container, Grid } from "@mui/material"
import styled, { keyframes } from "styled-components"

export const ReadyToFlashContainer = styled(Container)`
  background-color: rgba(217, 217, 217, 0.5);
  border-radius: 5px;
  @media (min-width: 360px) {
    text-align: center;
    padding: 10px;
  }
  @media (min-width: 768px) {
    text-align: left;
  }
`
export const TitleContainer = styled(Container)`
  background-color: transparent;
  text-align: left;
`
export const CommonTitleDiv = styled.div`
  background-color: gray;
`
export const BoxDiv = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: space-around;
`
export const Title = styled.h3`
  color: #e44411;
  padding-left: 10px;
  @media (min-width: 360px) {
    text-align: center;
  }
  @media (min-width: 768px) {
    text-align: left;
  }
`
export const FlashButtonGrid = styled(Grid)`
  align-self: flex-end;
  margin-right: 60px;
  @media (min-width: 360px) {
    align-self: center;
  }
  @media (min-width: 768px) {
    align-self: flex-end;
  }
`
export const DefaultButton = styled.button`
  width: 155px;
  color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: 500;
`
export const FlashButton = styled(DefaultButton)`
  background-color: #e44411;
  height: 35px;
  cursor: pointer;
  @media (min-width: 360px) {
    margin-bottom: 10px;
  }
  @media (min-width: 768px) {
    margin-bottom: 10px;
    padding: 10px auto;
  }
`
export const ReadyToFlashButtonGrid = styled(Grid)`
  @media (min-width: 360px) {
    align-self: left;
  }
`
export const ReadyToFlashButton = styled(DefaultButton)`
  background-color: #207b18;
  width: 155px;
  line-height: 6px;
  @media (min-width: 360px) {
    margin: 10px;
  }
  @media (min-width: 768px) {
    padding: 10px 20px;
    margin: 10px 10px 30px 10px;
  }
`
export const DeviceNameButton = styled(DefaultButton)`
  background-color: #2f4b8a;
  height: 35px;
  font-size: 14px;
  font-weight: semi-bold;
  line-height: 10px;
`
const blink = keyframes`
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.9;
  }
`

export const BlinkingButton = styled(DeviceNameButton)`
  animation: ${blink} 1s infinite;
`
