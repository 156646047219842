import styled from "styled-components"

export const CursorPointerDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: max-content;
`

export const RedCursorText = styled.p`
  color: red;
  margin-bottom: 0;
  margin-top: 10px;
  font-weight: bold;
`

export const GreenBoundLine = styled.div`
  background-color: green;
  width: 4px;
  height: 24px;
  border-radius: 4px;
  position: absolute;
  top: 0;
`

export const GreenBoundText = styled.p`
  color: green;
  margin-bottom: 10px;
  margin-top: 0;
`

export const CustomSliderDiv = styled.div`
  height: 24px;
  padding-top: 7px;
  display: flex;
  flex-direction: row;
`

export const GreenSubinterval = styled.div`
  height: 10px;
  background-color: green;
  width: 25%;
`

export const GraySubinterval = styled.div`
  height: 10px;
  background-color: lightgray;
  width: 75%;
`

export const LeftGraySubinterval = styled.div`
  width: 25%;
  height: 10px;
  border-radius: 6px 0 0 6px;
  background-color: lightgray;
`
export const MiddleGreenSubinterval = styled.div`
  width: 50%;
  height: 10px;
  background-color: green;
`

export const RightGraySubinterval = styled.div`
  width: 25%;
  height: 10px;
  border-radius: 0 6px 6px 0;
  background-color: lightgray;
`
