import styled from "styled-components"

export const RowAlignCenterDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const ValuesDiv = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
`

export const ExpectedValueDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

export const HelperTextDiv = styled(ValuesDiv)`
  align-items: center;
`
