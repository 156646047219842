import { DateTime } from "luxon"

export function getLocalDate(date: string): string {
  const localDate = DateTime.fromISO(date, { zone: "utc", setZone: true })
    .toLocal()
    .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
  return fixDateFormat(localDate)
}
const fixDateFormat = (date: string): string => {
  const regEx = /1970/i
  return regEx.test(date) ? "n/a" : date
}
