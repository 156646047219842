import { Select, TableContainer } from "@mui/material"
import { styled } from "@mui/material/styles"
import { css, styled as styles } from "styled-components"
import { DefaultButton, ReadyToFlashContainer } from "./ReadyToFlashStyle"
import { Flex } from "styles/ProgressReportStyles"

export const InProgressContainer = styled(ReadyToFlashContainer)``
export const InProgressDiv = styles.div`
  width: 100%;
  padding-bottom: 10px;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
export const ProgressStatusDiv = styles.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`
export const ToggleButtonDiv = styles.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  @media (min-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
  @media (min-width: 1024px) {
    width: 100%;
    flex-direction: row;
  }
`
export const CommonDiv = styles.div`
  margin: 10px 60px 10px auto;
  @media (max-width: 768px) {
    width: 100%;
    margin: 10px auto;
  }
  @media (min-width: 1024px) {
    width: 70%;
  }
`
export const FlashingStatusDiv = styled(CommonDiv)`
  width: 75%;
`

export const CameraImagesDiv = FlashingStatusDiv

export const StatusDiv = styled(CommonDiv)`
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
  @media (max-width: 412px) {
    width: 100%;
  }
`
export const ActionRequiredPopUp = styles.div`
  background: #efe189;
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 20px;
`
export const SpanBold = styles.span`
  font-weight: 500;
  padding-right: 10px;
`
export const ButtonDiv = styles.div`
  width: 100%;
  margin-right: 0;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    align-self: flex-end;
  }
  @media (min-width: 1024px) {
    align-self: flex-start;
  }
`
export const ShowButton = styled(DefaultButton)`
  background-color: #e44411;
  height: 35px;
  font-size: 14px;
  font-weight: semi-bold;
`
export const ShowButtonDiv = styles.div`
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ItalicPre = styles.pre`
  font-style: italic;
`
export const BoldPre = styles.pre`
  font-weight: bold;
`
export const TableMainContainer = styled(TableContainer)`
  width: 100% !important;
  margin: 10px auto 30px;
  border-radius: 5px;
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @media (max-width: 768px) {
    align-self: center;
  }
`
export const HorizontalLine = styles.hr`
  border: 1px solid black;
  margin: 50px 20px 100px 15px;
  opacity: 0.1;
  :last-child {
    display: none;
  }
`
export const DivInsideDeviceNameButton = styles.div`
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 8px;
`
export const ViewContainer = css`
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 5px;
  margin-bottom: 20px;
`
export const ProcessViewContainer = styles.div`
  ${ViewContainer}
  justify-content: space-evenly;
`
export const ConfigurationProcessViewContainer = styles.div`
  ${ViewContainer}
`
export const ButtonStyle = styles.button`
  width: 300px;
  height: 35px;
  color: white;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-weight: 500;
`
export const ReportDropDownDiv = styles.div`
  height: 60px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
export const ReportSelect = styled(Select)`
  background: white;
  width: 150px;
  height: 35px;
  margin-right: 20px;
  border-radius: 5px;
`
export const HrLine = styles.hr`
  color: rgba(128, 128, 128, 0.2);
`
export const FlexColumn = css`
  ${Flex}
  flex-direction: column;
`
export const DivWithItalicText = styles.div`
  ${FlexColumn}
  width: 100%;
  font-style: italic;
  padding-bottom: 20px;
`
export const FlexWithMargin = styles.div`
  ${Flex}
  width: 100%;
  margin-top: -20px;
  justify-content: center;
`
export const FlexWithWidth = css`
  ${Flex}
`
export const FlexWidth = styles.div`
  ${FlexWithWidth}
`
export const P = styles.p`
  color: #2f2f2f;
  margin-bottom: 5px;
`
