export const configurationRegexStarted = /^[1-3][0-9]-.*-started$/
export const calibrationRegexStarted = /^[4][0-9]-.*-started$/
export const assuranceRegexStarted = /^[5][0-9]-.*-started$/
export const assuranceRegexFinished = /^[6][0-9]-.*-ended$/

export const startingExecutionRegex = /^Starting execution of/
export const includesString = /00-update-state|99z-update-state/
export const calibrationTransitionStart =
  /Starting execution of script ".*\/10-run-auto-calibration.py"/

export const includesRebootString = /.*[1-9][0-9]z-reboot/

export const completedAssuranceStateRegex =
  /Execution of script "80-finalize-and-reboot\/99z-reboot" completed with exit code 0./
