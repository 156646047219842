type MessageListener = (ev: MessageEvent) => any
type StateChangeListener = (sock: WebSocket, connected: boolean, ev: Event) => any

export type ReconnectingSocketState = {
  on: (fn: MessageListener) => void
  off: (fn: MessageListener) => void
  onStateChange: (fn: StateChangeListener) => () => void
  close: () => void | undefined
  getClient: () => WebSocket | null
  isConnected: () => boolean
}

export const reconnectingSocket = (
  url: string,
  reconnectIntervalMs = 2000,
): ReconnectingSocketState => {
  let client: WebSocket | null = null
  let isConnected = false
  let reconnectOnClose = true
  let messageListeners: MessageListener[] = []
  let stateChangeListeners: StateChangeListener[] = []

  function on(fn: MessageListener) {
    messageListeners.push(fn)
  }

  function off(fn: MessageListener) {
    messageListeners = messageListeners.filter((l) => l !== fn)
  }

  function onStateChange(fn: StateChangeListener) {
    stateChangeListeners.push(fn)
    return () => {
      stateChangeListeners = stateChangeListeners.filter((l) => l !== fn)
    }
  }

  function start() {
    client = new WebSocket(url)

    client.onopen = (ev) => {
      isConnected = true
      stateChangeListeners.forEach((fn) => fn(ev.target as WebSocket, true, ev))
    }

    // Override client.close() so the user can close without triggering a reconnect.
    const close = client.close
    client.close = () => {
      reconnectOnClose = false
      close.call(client)
    }

    client.onmessage = (event) => {
      messageListeners.forEach((fn) => fn(event))
    }

    client.onerror = (e) => console.error(e)

    client.onclose = (ev) => {
      isConnected = false
      stateChangeListeners.forEach((fn) => fn(ev.target as WebSocket, false, ev))

      if (!reconnectOnClose) {
        console.log("ws closed by app", ev)
        return
      }

      console.log(`ws closed by server, reconnect in ${reconnectIntervalMs}ms`, ev)
      setTimeout(start, reconnectIntervalMs)
    }
  }

  start()

  return {
    on,
    off,
    onStateChange,
    close: () => client?.close(),
    getClient: () => client,
    isConnected: () => isConnected,
  }
}
