import styled from "styled-components"

export const AlignCenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const BoldSpan = styled.span`
  font-weight: 500;
`

export const FormDiv = styled.div`
  padding: 40px 60px 0 60px;
`

export const FormControlDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  span {
    padding-bottom: 10px;
  }
`

export const SubmitButton = styled.button`
  margin-bottom: 30px;
  cursor: pointer;
  height: 35px;
  color: white;
  border: none;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  font-weight: 500;
  width: 120px;
  background: #e44411;
  text-transform: uppercase;
`
