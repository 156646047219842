import { Dialog, DialogTitle, IconButton } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { CreateAxiosConfig } from "pages/AxiosConfig"
import { useEffect, useState } from "react"
import styled from "styled-components"
import {
  CalibrationVersion,
  CurrentVersions,
  FactoryBackendVersion,
  FactoryFrontend,
  Version,
} from "variables/sharedVariables"
import packageJson from "../../../package.json"
import { AxiosResponse } from "axios"
import closeIcon from "assets/icons/CloseIcon.svg"

const useStyles: any = makeStyles(() => ({
  dialog: {
    height: 310,
    width: 500,
    fontSize: 16,
  },
}))
const DialogTitleStyled = styled(DialogTitle)`
  color: #e44411;
  text-align: center;
  word-break: break-all;
  font-weight: bold !important;
`
const MainContentDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
`
const Versions = styled.p`
  font-weight: bold;
`
interface VersionsDialogProps {
  open: boolean
  onClose: () => void
}

export const SoftwareVersionsDialog = ({ open, onClose }: VersionsDialogProps) => {
  const styles = useStyles()
  const [backendVersion, setBackendVersion] = useState({ backend_version: "" })
  const [calibrationVersion, setCalibrationVersion] = useState("")
  const axiosAlphasenseFactory = CreateAxiosConfig("/api/v1")
  const axiosCalibrationVersion = CreateAxiosConfig("/calibration-server")

  useEffect(() => {
    axiosAlphasenseFactory
      .get("/version")
      .then((response: AxiosResponse) => setBackendVersion(response.data))
    axiosCalibrationVersion
      .get("/version")
      .then((response: AxiosResponse) => setCalibrationVersion(response.data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Dialog open={open} classes={{ paper: styles.dialog }} onClose={onClose}>
      <IconButton
        aria-label="close"
        style={{ alignSelf: "self-end", marginTop: "10px", marginRight: "10px" }}
        onClick={onClose}
      >
        <img src={closeIcon} alt="close" />
      </IconButton>
      <DialogTitleStyled>
        {CurrentVersions}
        <br />
        {Version}
      </DialogTitleStyled>
      <MainContentDiv>
        <div>
          <p>{FactoryFrontend}</p>
          <p>{FactoryBackendVersion}</p>
          <p>{CalibrationVersion}</p>
        </div>
        <div>
          <Versions>{packageJson.version}</Versions>
          <Versions>{backendVersion.backend_version}</Versions>
          <Versions>{calibrationVersion}</Versions>
        </div>
      </MainContentDiv>
    </Dialog>
  )
}
