import axios from "axios"

export const CreateAxiosConfig = (url: string) => {
  const newInstance = axios.create({
    baseURL: url,
    headers: {
      Accept: "application/json",
    },
  })
  newInstance.interceptors.request.use(
    function (config: any) {
      const accessToken = localStorage.getItem("token")
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }
      return config
    },
    (error) => {
      Promise.reject(error.response || error.message)
    },
  )
  return newInstance
}
