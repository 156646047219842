/* eslint-disable max-len */
import { Translations } from "./types"

export const stateTranslations = {
  readyForFlashing: "Ready for Flashing",
  waitingForFlashing: "Waiting for Flashing",
  flashing: "Flashing",
  installingOS: "Installing OS",
  installingSevensenseSoftware: "Installing Sevensense Software",
  completed: "Completed",
}

export const flashingDescriptionTranslations = {
  readyForFlashing:
    "The device is connected to the Flashing Station, powered up and in “Recovery Mode”, ready to be (re-)flashed.",
  waitingForFlashing:
    "The flashing task has been issued for this device. The device is waiting for the flashing to commence.",
  flashing: "Flashing of the NVIDIA Jetson board is currently ongoing.",
  identifyingDevice: "Identifying Device",
  installingOS:
    "Disk partitions are configured and the operating system is being installed and configured.",
  installing: "Installing Software",
  installingSevensenseSoftware: "Installing Sevensense software packages.",
  completed:
    "The device successfully completed the flashing and software installation process. It can now be taken off the Flashing Station.",
  failed: "A failure has occurred.",
  failedAcknowledged: "A failure has occurred. The failure has been acknowledged by a user.",
  unavailable: "No state information available for this device. ",
}

export const bootstrappingDescriptionTranslations = {
  configuration: "The robot is undergoing configuration of network and sensors.",
  calibration: "The robot is calibrating its sensors in the calibration arena.",
  acceptance: "The robot is undergoing final validation checks. ",
  completedBootstrap: "The Alphasense Factory process of this robot has completed successfully.",
  failedBootstrap: "A failure has occurred.",
  unavailableBootstrap: "No state information available for this robot.",
  unknown: "The state of this robot is unknown.",
  clickHere: "Click here ",
  report: "to see the report.",
}

export const CalibrationSubStates: Translations = {
  AutoCalibrationRegistration: "Initializing Calibration",
  WaitingForInitialCalibration: "Initializing Calibration",
  StartQRReader: "Initializing Calibration",
  WaitingForHumanOperator: "Please move the robot into the Calibration Arena",
  StopQRReader: "Ready to record data",
  RotateInPlace: "Recording calibration data",
  PreCalibDataUploading: "Recording calibration data",
  WaitingForPreCalibrationResult: "Recording calibration data",
  CalibrationRepeat: "Recording calibration data",
  DataUploading: "Uploading calibration data",
  UploadingFactoryCalibrationData: "Uploading calibration data",
  WaitingForCalibrationResult: "Waiting for calibration result",
  UpdateMap: "Waiting for calibration result",

  ReadyForManualCalibration: "Initializing Calibration",
  AutoStartByBootstrap: "Initializing Calibration",
  AstiSemiAutomaticCalibration: "Initializing Calibration",
  WaitingForArenaLocalization: "Please move the robot into the Calibration Arena",
  ManualDataRecording: "Recording calibration data",
  LocalPreprocessing: "Pre-processing calibration data",
  SuccessfullyCompleted: "Calibration completed successfully",

  LocallyExtractingFactoryCalibrationData: "Pre-processing calibration data",
  WaitingForFactoryCalibration: "Waiting for Calibration Result",
  WaitingForRotateInPlaceCalibration: "Waiting for calibration result",
  WaitingForInitialConfiguration: "Initializing Calibration",

  PreCalibSanityCheckFailed: "Calibration checks failed",
  SanityCheckFailed: "Calibration checks failed",
  SanityChecksFailedForFactory: "Calibration checks failed",
  Aborted: "Calibration aborted by robot",
  ServerCalledAbort: "Calibration aborted by server",
  Error: "Error",

  StartCalibration: "Connecting to calibration server",
  ready_for_recording: "Ready to record data",
  recording_completed: "Recording completed",

  LocallyExtractingRotateInPlaceCalibrationData: "Recording calibration data",
  RotateInPlaceDataCheck: "Recording calibration data",
  UploadingRotateInPlaceCalibrationData: "Recording calibration data",
  RecordingCalibrationData: "Recording calibration data",
  LocallyExtractingFullFactoryCalibrationData: "Pre-processing calibration data",
  FullFactoryDataCheck: "Pre-processing calibration data",
  UploadingFullFactoryCalibrationData: "Uploading calibration data",
  WaitingForFullFactoryCalibration: "Waiting for calibration result",
}
