import { FlexRow, LeftRowWidth, RightRowWidth } from "styles/DetailViewStyle"
import { ConfigurationProcessViewContainer } from "styles/FlashStationStyle/InProgressStyle"
import { CameraImages } from "variables/sharedVariables"
import { ConvertedImages } from "./ConvertedImages"
import { CreateAxiosConfig } from "pages/AxiosConfig"
import { useEffect } from "react"
import { useNotify } from "react-admin"
import { AxiosError } from "axios"

interface CameraImagesViewProps {
  deviceName: string
}

export const CameraImagesView = ({ deviceName }: CameraImagesViewProps) => {
  const axiosAlphasenseFactory = CreateAxiosConfig("/api/v1")
  const notify = useNotify()

  const startFetchingImages = () => {
    axiosAlphasenseFactory
      .post(`/devices/${deviceName}/images`)
      .catch((error: Error | AxiosError) => notify("Failed to streaming images", error))
  }

  const stopFetchingImages = () => {
    axiosAlphasenseFactory
      .delete(`/devices/${deviceName}/images`)
      .catch((error: Error | AxiosError) => notify("Failed to stop image streaming", error))
  }

  useEffect(() => {
    startFetchingImages()
    return () => {
      stopFetchingImages()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ConfigurationProcessViewContainer>
      <FlexRow>
        <LeftRowWidth>
          <p>{CameraImages}</p>
        </LeftRowWidth>
        <RightRowWidth>{deviceName && <ConvertedImages {...{ deviceName }} />}</RightRowWidth>
      </FlexRow>
    </ConfigurationProcessViewContainer>
  )
}
