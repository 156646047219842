import { useEffect, useState } from "react"
import { useNotify, useRedirect } from "react-admin"
import { logOut } from "utility/user_authentication"
import { AuthFailedError, FlashingStationTitle } from "../../variables/sharedVariables"
import { CreateAxiosConfig } from "../AxiosConfig"
import { NavBar } from "../../Components/NavBar/NavBarMenu"
import { DeviceProps, DeviceState, EState, EStateKey } from "../../utility/types"
import { Finished } from "../../Components/FlashingStation/Finished"
import { InProgress } from "../../Components/FlashingStation/InProgress"
import { ReadyToFlash } from "../../Components/FlashingStation/ReadyToFlash"
import axios, { AxiosError, AxiosResponse } from "axios"
import { TitleContainer } from "styles/FlashStationStyle/ReadyToFlashStyle"
import {
  CommonDivContainer,
  CommonSessionContainer,
  CommonTitleDiv,
  MainDiv,
  MainTitle,
} from "styles/CommonStyles"

export const FlashingStation = () => {
  const [deviceData, setDeviceData] = useState<DeviceState[]>([])
  const notify = useNotify()
  const redirect = useRedirect()
  const axiosAlphasenseFactory = CreateAxiosConfig("/api/v1")

  const getReadyToFlashDevices = () => {
    axiosAlphasenseFactory
      .get("/devices")
      .then((response: AxiosResponse) => {
        setDeviceData(
          response.data
            .map((data: DeviceProps) =>
              data.device_state.map((device: DeviceState) => {
                return {
                  id: device.id,
                  port_name:
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    device.human_readable_port_name || device.port_name!.split("/", 2).pop(),
                  state: EState[device.state as unknown as EStateKey],
                  device_name: device.device_name,
                  transitions: device.transitions.sort((deviceA, deviceB) =>
                    Date.parse(deviceA.timestamp) > Date.parse(deviceB.timestamp) ? 1 : -1,
                  ),
                }
              }),
            )
            .flat(),
        )
      })
      .catch((e: Error | AxiosError) => {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 401 || e.response?.status === 502) {
            notify(AuthFailedError, { type: "error" })
            logOut(redirect)
          } else if (e.response?.status === 403) {
            console.error("No permission to access this resources", e)
            redirect("/bootstrapping")
          } else {
            console.error("Failed to get bootstrapping devices", e)
          }
        } else {
          console.error("Unexpected error:", e)
        }
      })
  }

  useEffect(() => {
    getReadyToFlashDevices()
    const intervalId = setInterval(() => getReadyToFlashDevices(), 1000)
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CommonDivContainer>
      <NavBar />
      <MainDiv>
        <CommonTitleDiv>
          <TitleContainer>
            <MainTitle>{FlashingStationTitle}</MainTitle>
          </TitleContainer>
        </CommonTitleDiv>
        <CommonSessionContainer>
          <ReadyToFlash {...{ deviceData }} />
        </CommonSessionContainer>
        <CommonSessionContainer>
          <InProgress {...{ deviceData }} />
        </CommonSessionContainer>
        <CommonSessionContainer style={{ marginBottom: "50px" }}>
          <Finished {...{ deviceData }} />
        </CommonSessionContainer>
      </MainDiv>
    </CommonDivContainer>
  )
}
