import { Button, Dialog, DialogActions, DialogContentText, DialogTitle } from "@mui/material"
import { useStyles } from "pages/ErrorDialogBox/ErrorNotification"
import {
  Abort,
  Cancel,
  ConfirmationDescription,
  ConfirmationTitle,
} from "variables/sharedVariables"

interface AbortProps {
  open: boolean
  setOpenAbortConfirmation: React.Dispatch<React.SetStateAction<boolean>>
  onClose: () => void
}
export const ConfirmationDialogBoxForAbort = ({
  open,
  setOpenAbortConfirmation,
  onClose,
}: AbortProps) => {
  const styles = useStyles()

  return (
    <Dialog open={open} classes={{ root: styles.root }}>
      <DialogTitle>{ConfirmationTitle}</DialogTitle>
      <DialogContentText id="alert-dialog-slide-description">
        {ConfirmationDescription}
      </DialogContentText>
      <DialogActions>
        <Button onClick={() => setOpenAbortConfirmation(false)}>{Cancel}</Button>
        <Button onClick={onClose}>{Abort}</Button>
      </DialogActions>
    </Dialog>
  )
}
