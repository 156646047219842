import { createContext } from "react"
import { WebSocketState } from "./websocket"

export const WebSocketContext = createContext<WebSocketState>({
  deviceHeartBeats: new Map(),
  images: new Map(),
  odometry: new Map(),
})
interface Props {
  children: any
  value: WebSocketState
}
export const WebSocketProvider = ({ children, value }: Props) => {
  return <WebSocketContext.Provider value={value}>{children} </WebSocketContext.Provider>
}
