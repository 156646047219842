import {
  Icon,
  LoginButton,
  LoginFormContainer,
  LoginFormDiv,
  SuccessMessage,
} from "../../styles/LoginStyle/LoginFormStyle"
import { Container, MainContainer, StyledGridItem } from "../../styles/LoginStyle/LoginStyles"
import { LogoContent } from "../Login/LogoContent"
import successIcon from "../../assets/images/success_icon.svg"
import { Next, PasswordSaved } from "../../variables/sharedVariables"
import { useRedirect } from "react-admin"

export const ConfirmPasswordPage = () => {
  const redirect = useRedirect()
  const nextOnClick = () => {
    redirect("/flashingstation")
  }
  return (
    <MainContainer>
      <Container>
        <LogoContent />
        <StyledGridItem item xs={6}>
          <LoginFormContainer>
            <LoginFormDiv>
              <SuccessMessage>{PasswordSaved}</SuccessMessage>
              <Icon src={successIcon} alt="success" />
              <LoginButton onClick={nextOnClick}>{Next}</LoginButton>
            </LoginFormDiv>
          </LoginFormContainer>
        </StyledGridItem>
      </Container>
    </MainContainer>
  )
}
