import { styled as styles } from "styled-components"
import { Box, FormControl, MenuItem } from "@mui/material"
import { styled } from "@mui/material/styles"
import { NavLink } from "react-router-dom"

export const NavBarContainer = styles.div`
  width: 100vw;
  background-color: white;
  padding: 0 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`
export const StyledBox = styled(Box)`
  width: 95vw;
  display: flex;
  justify-content: space-between;
  min-height: 60px;
`
export const BoxDiv = styled(Box)`
  display: flex;
  @media (min-width: 768px) {
    justify-content: space-between;
  }
`
export const NavLinks = styles.div`
  display: flex;
  @media (min-width: 768px) {
    width: 50vw;
    justify-content: center;
  }
`
export const NavLinkRight = styles.div`
  display: flex;
`
export const LogoLink = styles.a`
  margin: auto 0;
  text-decoration: none;
  @media (min-width: 768px) {
    width: 20vw;
  }
`
export const NavLinkItem = styles.a`
  position: relative !important;
  margin: auto 10px;
  color: black;
  text-decoration: none;
  justify-content: space-around;
  font-family: "Cabin" !important;
  font-size: 14px !important;
  line-height: 18px;
`
export const Logo = styles.img`
  margin: auto;
  height: 25px;
`
export const Title = styles.p`
  font-family: "Cabin";
  font-size: 10px;
  color: #2f2f2f;
  width: 20px;
  padding: 0 50px 0 25px;
  margin: 0 30px;
  @media (min-width: 768px) {
    padding: 0 40px 0 0;
    margin: 0 20px;
  }
  @media (min-width: 1024px) {
    font-size: 12px;
    padding: 0 50px 0 25px;
    margin: 0 30px;
  }
`
export const NavLinkBox = styles.button`
  height: 60px;
  background: none;
  border: transparent;

  &:focus {
    border-color: 4px solid #e44411;
  }
`
export const NavItemLink = styled(NavLink)`
  color: black;
  text-decoration: none;
  margin: auto 10px;
  font-family: "Cabin" !important;
  @media (min-width: 768px) {
    font-size: 14px;
  }
  @media (min-width: 1024px) {
    font-size: 18px;
  }
  &.active {
    border-bottom: 4px solid #e44411;
    padding: 20px 0px 20px 0px;
  }
`
export const FlashingStationLink = styled(NavItemLink)`
  color: #2f2f2f;
  @media (min-width: 768px) {
    margin: auto 10px auto 70px;
  }
`
export const FlashingStationLinkDisplayNone = styled(FlashingStationLink)`
  display: none;
`
export const StyledWrapper = styles.div`
  position: relative;
  margin: auto 0;
`

export const LogoutBox = styled(Box)`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: baseline;
`

export const StyledSpan = styles.p`
  color: #2f2f2f;
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  text-transform: uppercase;
  padding: 0 10px 0 20px;
`
export const StyledButton = styles.button`
  height: 30px;
  width: 110px;
  border: 1px solid rgba(48, 53, 68, 0.3);
  border-radius: 5px;
  background-color: rgba(30, 121, 248, 0.05);
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  &:focus {
    outline: none;
  }
`
export const LoggedDropDown = styled(FormControl)`
  height: 35px;
  width: auto;
  border-radius: 5px;
  background-color: rgba(217, 217, 217, 0.5);
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;
`
export const MenuItems = styled(MenuItem)`
  font-size: 14px;
`
