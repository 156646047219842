import {
  CameraAndButtonComponent,
  DivWithStatus,
  FlexRow,
  LeftRowWidth,
  RightRowWidth,
} from "styles/DetailViewStyle"
import {
  ConfigurationProcessViewContainer,
  ProcessViewContainer,
} from "styles/FlashStationStyle/InProgressStyle"
import { currentState } from "utility/helpingFunctions"
import { useContext } from "react"
import { ButtonsComponent } from "./ButtonsComponent"
import { OdometrySignal } from "variables/sharedVariables"
import { WebSocketContext } from "Provider/WebSocketProvider"
import {
  BootstrapStateInfo,
  DeviceStatus,
  EOdometry,
  isFailureStatus,
  EState,
  DeviceInfo,
} from "utility/types"

export interface DeviceDetailProps {
  inProgressStates: EState | "Unknown"
  deviceStatus: DeviceStatus
  isDeviceActive: boolean
  bootstrapStateInfo?: BootstrapStateInfo
  device: DeviceInfo
}

const DisplayCurrentState = (inProgressStates: string, isStateFailed: boolean) => (
  <FlexRow style={{ marginTop: "20px" }}>
    <LeftRowWidth>
      <p>{currentState(inProgressStates)}:</p>
    </LeftRowWidth>
    <RightRowWidth style={{ marginTop: "10px" }}>
      <DivWithStatus style={{ color: isStateFailed ? "red" : "green" }}>
        {isStateFailed ? "Failed" : "Succeeded"}
        <br />
      </DivWithStatus>
    </RightRowWidth>
  </FlexRow>
)

export const DetailedViewOfTheState = ({
  inProgressStates,
  deviceStatus,
  device,
  isDeviceActive,
  bootstrapStateInfo,
}: DeviceDetailProps) => {
  const { odometry } = useContext(WebSocketContext)
  const isStateFailed = isFailureStatus(deviceStatus)

  return (
    <>
      {currentState(inProgressStates) === EState.CONFIGURATION ? (
        <ConfigurationProcessViewContainer>
          {DisplayCurrentState(inProgressStates, isStateFailed)}
          <FlexRow>
            <LeftRowWidth>
              <p>{OdometrySignal}</p>
            </LeftRowWidth>
            <RightRowWidth>
              <p>{odometry.get(device.hostname) || EOdometry.noSignal}</p>
            </RightRowWidth>
          </FlexRow>
          <CameraAndButtonComponent>
            <LeftRowWidth />
            <RightRowWidth>
              <ButtonsComponent
                {...{
                  deviceStatus,
                  inProgressStates,
                  device,
                  isDeviceActive,
                  bootstrapStateInfo,
                }}
              />
            </RightRowWidth>
          </CameraAndButtonComponent>
        </ConfigurationProcessViewContainer>
      ) : (
        <ProcessViewContainer>
          {DisplayCurrentState(inProgressStates, isStateFailed)}
          <CameraAndButtonComponent>
            <LeftRowWidth></LeftRowWidth>
            <ButtonsComponent
              {...{
                deviceStatus,
                inProgressStates,
                device,
                isDeviceActive,
                bootstrapStateInfo,
              }}
            />
          </CameraAndButtonComponent>
        </ProcessViewContainer>
      )}
    </>
  )
}
