import { Dialog, Paper, IconButton, TextField } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { ColumnWithoutJustified } from "styles/ProgressReportStyles"
import CloseIcon from "assets/icons/CloseIcon.svg"
import {
  AlignCenterDiv,
  BoldSpan,
  FormControlDiv,
  FormDiv,
  SubmitButton,
} from "styles/RobotIdentityDialogStyle"
import { RobotIdentity, Save } from "variables/sharedVariables"
import { DeviceWithFrontendTransitions } from "utility/types"
import { FormEvent, useState } from "react"
import { CreateAxiosConfig } from "pages/AxiosConfig"
import axios, { AxiosError, AxiosResponse } from "axios"
import { logOut } from "utility/user_authentication"
import { useRedirect } from "react-admin"

interface RobotIdentityDialogProps {
  toggleIdentityRobotDialog: string
  setToggleIdentityRobotDialog: React.Dispatch<React.SetStateAction<string>>
  deviceWithFrontendTransitions: DeviceWithFrontendTransitions
  callbackOnSubmit: () => void
}

const customStyles = makeStyles(() => ({
  dialogContainer: {
    justifyContent: "center",
    width: "100vw",
    maxWidth: "450px",
    background: "white",
  },
}))

export const RobotIdentityDialog = ({
  toggleIdentityRobotDialog,
  setToggleIdentityRobotDialog,
  deviceWithFrontendTransitions,
  callbackOnSubmit,
}: RobotIdentityDialogProps) => {
  const [serialNo, setSerialNo] = useState(deviceWithFrontendTransitions.robot_serial_no ?? "")
  const [errorField, setErrorField] = useState(false)
  const axiosAlphasenseFactory = CreateAxiosConfig("/api/v1")
  const classes = customStyles()
  const redirect = useRedirect()

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (!serialNo?.trim()) {
      setErrorField(true)
      return
    }

    axiosAlphasenseFactory
      .patch(`/bootstrapping/${deviceWithFrontendTransitions.hostname}`, {
        robot_serial_no: serialNo,
      })
      .then((response: AxiosResponse) => {
        if (response.status === 204) {
          callbackOnSubmit()
          setToggleIdentityRobotDialog("")
        }
      })
      .catch((e: Error | AxiosError) => {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 401 || e.response?.status === 403) {
            logOut(redirect)
            return null
          }
        } else {
          console.error("Unexpected error:", e)
        }
      })
  }

  return (
    <Dialog open={toggleIdentityRobotDialog === deviceWithFrontendTransitions.hostname}>
      <Paper className={classes.dialogContainer}>
        <ColumnWithoutJustified>
          <IconButton
            aria-label="close"
            onClick={() => setToggleIdentityRobotDialog("")}
            style={{ alignSelf: "self-end" }}
          >
            <img src={CloseIcon} alt="Close" />
          </IconButton>
          <AlignCenterDiv>
            <h2>{RobotIdentity}</h2>
          </AlignCenterDiv>
          <FormDiv>
            <form onSubmit={onSubmit}>
              <FormControlDiv>
                <BoldSpan>Robot S/N</BoldSpan>
                <TextField
                  variant="outlined"
                  value={serialNo}
                  onChange={(e) => setSerialNo(e.target.value)}
                  error={errorField}
                  helperText={errorField ? "This field is required!" : ""}
                />
              </FormControlDiv>
              <FormControlDiv>
                <BoldSpan>Robot Model</BoldSpan>
                <TextField
                  disabled
                  variant="outlined"
                  value={deviceWithFrontendTransitions.robot_model_name}
                />
              </FormControlDiv>
              <FormControlDiv>
                <BoldSpan>Device S/N</BoldSpan>
                <TextField disabled variant="outlined" value={toggleIdentityRobotDialog} />
              </FormControlDiv>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <SubmitButton type="submit">{Save}</SubmitButton>
              </div>
            </form>
          </FormDiv>
        </ColumnWithoutJustified>
      </Paper>
    </Dialog>
  )
}
