// This file is the Typescript equivalent to `result.py` from 7s-inspect.

export enum Status {
  Fail = "FAIL",
  Pass = "PASS",
  Skip = "SKIP",
}

/**
 * A `CreatedNotification` from sev-notification-system.
 * Eventually, we should provide the translations through the backend.
 */
export interface CreatedNotification {
  message: string
  metadata: Record<string, string> | null
  module: number
  severity: string // This should actually be an ENUM, but string is good enough for now.
  source_id: number
  status_code: number
}

/**
 * A result as returned from 7s-inspect and other bootstrap/factory checks.
 * Please check the docstring in `result.py` from 7s-inspect for details.
 */
export interface FactoryResult {
  check_name: string
  status: Status
  reason: CreatedNotification
  duration_s: number
  children: FactoryResult[]
}

// From the backend API, we receive the result with additional metadata.
export interface FactoryResultWithMetadata {
  result: FactoryResult
  // This is a timestamp in ISO 8601 format.
  // It corresponds to the state update timestamp in device monitoring.
  timestamp: string
}

// Some metadata keys which commonly occur.
export interface WellknownMetadata {
  // From 7s-inspect `evaluate.py` or `expectations.py`.
  value?: string // Arbitrary stringified value

  // From 7s-inspect `evaluate.py`.
  exception?: string // String
  error?: string // String
  pass?: string // Integer
  fail?: string // Integer
  skip?: string // Integer

  // From 7s-inspect `expectations.py`.
  expected_value?: string // Arbitrary stringified value
  lower?: string // Usually a number
  upper?: string // Usually a number
  expected_values?: string // Arbitrary stringified list of values
  container_value?: string // Arbitrary stringified value
  count?: string // Integer
  desired_type?: string // String (Python type name)

  // From report parsing/conversion.
  possible_causes?: string // []string encoded as JSON.
}
