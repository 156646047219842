import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material"
import CloseIcon from "assets/icons/CloseIcon.svg"
import { logOut } from "utility/user_authentication"
import { CreateAxiosConfig } from "pages/AxiosConfig"
import { useRedirect } from "react-admin"
import { useState } from "react"
import axios, { AxiosError, AxiosResponse } from "axios"
import { SubmitShareWith7SButton } from "styles/DetailViewStyle"
import { Send, ShareWith7SPlaceholder } from "variables/sharedVariables"

interface ShareWith7SDialogProps {
  deviceName: string
  onClose: () => void
}

export const ShareWith7SDialog = ({ deviceName, onClose }: ShareWith7SDialogProps) => {
  const axiosAlphasenseFactory = CreateAxiosConfig("/api/v1")
  const redirect = useRedirect()
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    setLoading(true)

    axiosAlphasenseFactory
      .post(`send_support_request/${deviceName}`, { message })
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          onClose()
          setLoading(false)
        }
      })
      .catch((e: Error | AxiosError) => {
        setLoading(false)
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 401 || e.response?.status === 502) {
            logOut(redirect)
            return null
          }
        } else {
          console.error("Unexpected error:", e)
        }
      })
  }

  return (
    <Dialog open={!!deviceName} onClose={onClose}>
      <form style={{ width: "450px" }} onSubmit={(e) => onSubmit(e)}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: "10px", top: "10px" }}
        >
          <img src={CloseIcon} alt="Close" />
        </IconButton>
        <DialogTitle
          sx={{
            color: "#E44411",
            textAlign: "center",
            wordBreak: "break-all",
            fontWeight: "bold",
            fontSize: "1.5rem",
            paddingTop: "30px",
            paddingBottom: "20px",
          }}
        >
          Share with Sevensense
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={4}
            label="Message"
            onChange={(e) => setMessage(e.target.value)}
            placeholder={ShareWith7SPlaceholder}
            sx={{ marginTop: "10px" }}
          />
        </DialogContent>
        <DialogActions sx={{ margin: "auto", justifyContent: "center" }}>
          <SubmitShareWith7SButton type="submit">
            {loading ? <CircularProgress style={{ color: "white" }} size="1rem" /> : Send}
          </SubmitShareWith7SButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
