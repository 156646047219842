import styled from "styled-components"
import { DefaultButton, ReadyToFlashButton } from "./ReadyToFlashStyle"

export const FinishedButton = styled(ReadyToFlashButton)`
  background-color: #207b18;
  opacity: 0.8;
  line-height: 4px;
  width: 155px;
  min-height: 35px;
  height: auto;
`
export const ClearAllButton = styled(DefaultButton)`
  height: 35px;
  margin-right: 15px;
  margin-bottom: 20px;
  align-self: flex-end;
`
export const DetailViewDiv = styled.div`
  width: 80%;
  margin: 20px 20px 20px auto;
  @media (max-width: 768px) {
    width: 100%;
    padding: 10px 10px;
  }
  @media (min-width: 1024px) {
    width: 80%;
    margin: 20px auto;
  }
`
export const FinishedStateDiv = styled.div`
  display: flex;
  flex-direction: column;
`
export const FinishedDevices = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
export const CloseButton = styled.span`
  width: 20px;
  height: 20px;
  border: transparent;
  float: right;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background: white;
  color: #e44411;
  font-weight: bold;
  cursor: pointer;
  margin-top: -5px;
  margin-right: -15px;
  padding-top: 8px;
`
export const DeviceName = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
`
export const Input = styled.input`
  background: #d9d9d9;
  width: 100px;
  min-height: 34px;
  border-radius: 4px;
  border-color: transparent;
  padding: 10px;
  margin-bottom: 10px;
`
