import { CalibrationSubStates } from "./translations"

export interface DeviceInfo {
  // "alphasense-123"
  hostname: string
  // "alphasense"
  type: string
  // "123"
  id: string
}

export interface Transition {
  timestamp: string
  previous_state: EStateKey
  new_state: EStateKey
  new_description: string
}
export interface DeviceState {
  id: number
  port_name?: string
  human_readable_port_name?: string
  state: EState
  device_name?: string
  description: string
  transitions: Transition[]
}

export interface FlashingStationData {
  location: string
  stationName: string
}
export interface DeviceProps {
  device_state: DeviceState[]
}

export interface BootstrappingDevice {
  hostname: string
  is_bootstrapping_active: boolean
  robot_model_name?: string
  robot_serial_no?: string
}
export interface BootstrappingTransition {
  message: string
  detail: string
  severity: number
  timestamp: string
}
export interface DeviceWithTransitions extends BootstrappingDevice {
  transitions: BootstrappingTransition[]
  calibrationTransitions: BootstrappingTransition[]
  bootstrapStateInfo?: BootstrapStateInfo
  isDeviceActive: boolean
}

export interface BootstrapStateInfo {
  node: string
  current_script_number: number
  total_number_of_scripts: number
  stdout: string
  stderr: string
  exit_code: number
  shutdown_pending: boolean
}

export interface DeviceWithFrontendTransitions extends BootstrappingDevice {
  isOldReleaseVersion: boolean
  session: SessionType
  status: DeviceStatus
  transitions: FrontendTransition[]
  isAborted: boolean
  bootstrapStateInfo?: BootstrapStateInfo
  isDeviceActive: boolean
}
export interface BackendTransition {
  message: string
  detail: string
  severity: number
  timestamp: string
}
export interface FrontendTransition {
  state: EState
  substate: string
  status: string
  timestamp: string
}

export enum EState {
  READY_FOR_FLASHING = "Ready for Flashing",
  WAITING_FOR_FLASHING = "Waiting for Flashing",
  FLASHING = "Flashing",
  IDENTIFYING_DEVICE = "Identifying Device",
  INSTALLING = "Installing Software",
  COMPLETED = "Completed",
  FAILED = "Failed",
  SUCCESS = "Success",
  INPROGRESS = "In Progress",
  FAILED_ACKNOWLEDGED = "Failed and Acknowledged",
  UNAVAILABLE = "Unavailable",
  CONFIGURATION = "Configuration",
  CALIBRATION = "Calibration",
  ACCEPTANCE = "Quality Assurance",
  UNKNOWN_PORT = "Unknown Port",
  UNKNOWN = "Unknown",
}
export type EStateKey = keyof typeof EState

export type SubState = keyof typeof CalibrationSubStates

export type Translations = {
  [key: string]: string
}
export enum ButtonText {
  start = "START",
  abort = "ABORT",
}
export enum SessionType {
  pinnedSession = "pinnedSession",
  actionRequiredSession = "actionRequiredSession",
  inProgressSession = "inProgressSession",
  unknown = "unknown",
}

export enum DeviceStatus {
  NotStarted = "Not Started",
  Ok = "Ok",
  BootstrapFailed = "Bootstrap Failed",
  CalibrationFailed = "Calibration Failed",
  CalibrationSanityCheckFailed = "Calibration Sanity Check Failed",
  ManualCheck = "Manual Check",
}

export const isFailureStatus = (status: DeviceStatus): boolean => {
  return [
    DeviceStatus.BootstrapFailed,
    DeviceStatus.CalibrationFailed,
    DeviceStatus.CalibrationSanityCheckFailed,
  ].includes(status)
}

export enum EOdometry {
  standstill = "Standstill",
  forward = "Forward",
  backward = "Backward",
  noSignal = "No Signal",
  leftTurn = "Left Turn",
  rightTurn = "Right Turn",
  unknown = "Unknown",
}
