import { Button, Dialog, DialogActions, DialogContentText, DialogTitle } from "@mui/material"
import { makeStyles } from "@mui/styles"
import styled from "styled-components"
import { AcknowledgeButton, ErrorNotificationTitle } from "../../variables/sharedVariables"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useStyles: any = makeStyles(() => ({
  root: {
    "& .MuiDialog-paper": {
      height: "300px",
      minWidth: "550px",
      borderRadius: "5px",
      textAlign: "left",
      paddingTop: "30px",
      paddingRight: "20px",
      paddingLeft: "20px",
    },
    "& .MuiDialogTitle-root": { color: "#e44411", textAlign: "center" },
    "& .MuiDialogActions-root": { margin: "auto" },
    "& .MuiDialogContentText-root": { margin: "20px" },
    "& .MuiButton-root": { background: "#e44411", color: "white", minWidth: "150px" },
  },
}))

const Span = styled.span`
  font-weight: bold;
`

interface ErrorPopUpProps {
  open: boolean
  onClose: () => void
  onAcknowledge: () => void
  portName: string
  deviceName: string
}

export const ErrorNotification = ({
  open,
  onClose,
  onAcknowledge,
  portName,
  deviceName,
}: ErrorPopUpProps) => {
  const rootStyle = useStyles()

  const handleOnClose = (_event: any, reason: "backdropClick" | "escapeKeyDown") => {
    // In Material UI v5 the Dialog's property 'disableBackdropClick' is deprecated.
    // We need to manually check for the backdropClick in the Dialog's onClose function.
    if (reason && reason === "backdropClick") {
      console.log("backdropClicked. Not closing dialog.")
      return
    }
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleOnClose} classes={{ root: rootStyle.root }}>
      <DialogTitle>{ErrorNotificationTitle}</DialogTitle>
      <DialogContentText id="alert-dialog-slide-description">
        Device on port <Span>{portName}</Span> (Hostname: <Span>{deviceName}</Span>) failed. To
        retry unplug/replug the device. If this happens repeatedly please contact Sevensense and
        provide the port and the hostname as information.
      </DialogContentText>
      <DialogActions>
        <Button onClick={onAcknowledge}>{AcknowledgeButton}</Button>
      </DialogActions>
    </Dialog>
  )
}
