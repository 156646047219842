type UserData = {
  userName: string | null
  pinnedIds: string[]
}

export const setUserWithPinnedDevices = (ids: string[]) => {
  const userName = localStorage.getItem("username")
  const userWithPinnedIds: UserData = { userName, pinnedIds: ids }
  localStorage.setItem("userWithPinnedIds", JSON.stringify(userWithPinnedIds))
}

export const getUserWithPinnedDevices = (): UserData | null => {
  const userWithPinnedIds = localStorage.getItem("userWithPinnedIds")
  return userWithPinnedIds ? JSON.parse(userWithPinnedIds) : null
}

export const getPinnedDevices = (): string[] => {
  const userData = getUserWithPinnedDevices()
  return userData ? userData.pinnedIds : []
}
