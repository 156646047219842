import { styled as styles } from "styled-components"
import background_smartphone from "../../assets/images/background_smartphone.png"
import background_tablet from "../../assets/images/background_tablet.png"
import background_desktop from "../../assets/images/background_desktop.png"
import { Grid } from "@mui/material"
import { styled } from "@mui/material/styles"

export const MainContainer = styles.div`
  height: 100vh;
  width: 100%;
  position: fixed;
  background: url(${background_smartphone}) no-repeat fixed;
  background-size: cover;
  background-position-x: 0px;

  @media (min-width: 768px) {
    background: url(${background_tablet}) no-repeat fixed;
    background-size: cover;
  }

  @media (min-width: 1024px) {
    background: url(${background_desktop}) no-repeat fixed;
    background-size: cover;
  }
`
export const Container = styled(Grid)`
  padding: 0;
  align-items: center;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: space-evenly;
  }
  @media (min-width: 1024px) {
    padding: 0 120px;
    overflow: hidden;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-items: start;
    column-gap: 50px;
  }
`
export const StyledGridItem = styled(Grid)`
  padding-bottom: 20px;
  flex-grow: 1;

  @media (min-width: 768px) {
    padding-top: 150px;
    padding-bottom: 80px;
    margin: 50px auto;
  }
  @media (min-width: 1280px) {
    padding-top: 50px;
    overflow-y: auto;
  }

  &.description {
    /* padding-left: 5vw; */
  }

  &.form {
    max-width: 425px;
    margin: 0 auto;
    padding: 0 20px;

    @media (min-width: 1280px) {
      max-width: 540px;
      padding: 0;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`
export const StyledGridItemLoginForm = styled(Grid)`
  padding-bottom: 20px;
  flex-grow: 1;
  @media (min-width: 768px) {
    padding-top: 50px;
    margin: auto 20px;
    min-width: 500px;
  }
  @media (min-width: 1280px) {
    padding-top: 50px;
    overflow-y: auto;
    min-width: auto;
    max-width: 600px;
  }
`
export const LogoTitleDiv = styles.div`
  display: flex;
`
export const Logo = styles.img`
  height: 32px;
  max-width: 188px;

  @media (min-width: 1280px) {
    margin-bottom: 50px;
  }
`

export const LogoTitle = styles.h1`
  min-height: 39px;
  color: #303544;
  font-family: Cabin;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 39px;
  margin: -4px 0 0 10px;

  @media (min-width: 600px) {
    max-width: 441px;
    color: #303544;
    font-size: 26px;
  }

  @media (min-width: 1280px) {
    min-height: 39px;
    color: #303544;
    font-family: Cabin;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 39px;
  }
`
export const SubTitle = styles.h2`
  font-size: 25px;
`
export const Content = styles.p`
  font-size: 16px;
`
