import { CenteredTitle, DivWithPadding } from "styles/ProgressReportStyles"
import { Device, ReportResult, Timestamp } from "variables/sharedVariables"
import { DeviceInfo } from "utility/types"
import { Result } from "./Result"
import {
  DivWithItalicText,
  FlexWidth,
  FlexWithMargin,
} from "styles/FlashStationStyle/InProgressStyle"
import { getLocalDate } from "pages/LocalTimeZone"
import moment from "moment-timezone"
import { FactoryResultWithMetadata, Status } from "utility/factoryResultTypes"

export interface FactoryResultPageProps {
  device: DeviceInfo
  result: FactoryResultWithMetadata
}

const reportHeader = (device: DeviceInfo, status: Status, timeStamp: string) => {
  const date = timeStamp && new Date(timeStamp)
  const timeZone = date && moment.tz(date, moment.tz.guess()).format("z")
  const localDate = timeStamp && getLocalDate(timeStamp)

  return (
    <DivWithItalicText>
      <FlexWithMargin>
        <FlexWidth>
          <p>{Device}</p>
        </FlexWidth>
        <FlexWidth>
          <p style={{ paddingLeft: "20px" }}>{device.hostname}</p>
        </FlexWidth>
      </FlexWithMargin>
      {localDate && timeZone && (
        <FlexWithMargin>
          <FlexWidth>
            <p>{Timestamp}</p>
          </FlexWidth>
          <FlexWidth>
            <p style={{ paddingLeft: "20px" }}>{`${localDate} ${timeZone}`}</p>
          </FlexWidth>
        </FlexWithMargin>
      )}
      {status && (
        <FlexWithMargin>
          <FlexWidth>
            <p>{ReportResult}</p>
          </FlexWidth>
          <FlexWidth>
            <p
              style={{
                paddingLeft: "20px",
                fontWeight: "500",
                color:
                  status === Status.Fail ? "red" : status === Status.Pass ? "green" : "#ff9900",
              }}
            >
              {status}
            </p>
          </FlexWidth>
        </FlexWithMargin>
      )}
    </DivWithItalicText>
  )
}

export const FactoryResultPage = ({ device, result }: FactoryResultPageProps) => {
  return (
    <DivWithPadding>
      <CenteredTitle>{result.result.check_name}</CenteredTitle>
      <span>{reportHeader(device, result.result.status, result.timestamp)}</span>
      <Result data={result.result} />
    </DivWithPadding>
  )
}
