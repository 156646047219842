import { CreateAxiosConfig } from "pages/AxiosConfig"
import { useState } from "react"
import { useNotify } from "react-admin"
import { BootstrappingDevice, DeviceState, DeviceWithTransitions } from "./types"
import { AxiosError, AxiosResponse } from "axios"

export type Topic =
  | "fai_version"
  | "calibration_state"
  | "calibration_result_path"
  | "software_version"
  | "calibration_server_error"

export interface DeviceMonitoringState {
  message: string
  detail: string
  severity: number
}

//7s_ts has some issue where nothing can be imported so copying it here for now
class DefaultMap<K = string, V = unknown> extends Map<K, V> {
  get(key: K) {
    let v = super.get(key) // using get instead of has saves the extra lookup..
    if (v === undefined) {
      v = this.defaultValueGenerator()
      this.set(key, v)
    }
    return v
  }

  constructor(public defaultValueGenerator: () => V) {
    super()
  }
}

export const useDeviceStateHook = () => {
  const [deviceMonitoringStates, setDeviceMonitoringStates] = useState<
    DefaultMap<Topic, Map<string, DeviceMonitoringState>>
  >(
    new DefaultMap<Topic, Map<string, DeviceMonitoringState>>(
      () => new Map<string, DeviceMonitoringState>(),
    ),
  )
  const notify = useNotify()
  const axiosDeviceMonitoring = CreateAxiosConfig("/device-monitoring/api/v2")

  const callEndpoint = (deviceNames: string[], topic: Topic) => {
    axiosDeviceMonitoring
      .get(`/topic_states/${topic}`, { params: { devices: deviceNames.join(",") } })
      .then((response: AxiosResponse) =>
        setDeviceMonitoringStates((currentMap) => {
          if (!response.data) {
            return currentMap
          }
          const endpointMap = currentMap.get(topic)

          Object.entries<DeviceMonitoringState>(response.data).forEach(([deviceName, newState]) => {
            endpointMap?.set(deviceName, newState)
          })
          return currentMap
        }),
      )
      .catch((error: Error | AxiosError) => notify("Device monitoring not reachable", error))
  }

  const updateDeviceMonitoringState = (
    devices: DeviceState[] | DeviceWithTransitions[] | BootstrappingDevice[],
    endpoint: Topic,
  ) => {
    const validDeviceNames = devices
      .map((device: any) => device.device_name || device.hostname)
      .filter((deviceName) => deviceName)
    if (validDeviceNames.length >= 1) {
      callEndpoint(validDeviceNames, endpoint)
    }
  }

  return { deviceMonitoringStates, updateDeviceMonitoringState }
}
