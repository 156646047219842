import { WebSocketContext } from "Provider/WebSocketProvider"
import { useContext, useState } from "react"
import { CenterP, FrontendImage, JustifiedColumn, JustifiedFlex } from "styles/ProgressReportStyles"
import { EnlargedImages } from "./EnlargedImages"

interface ConvertedImageProps {
  deviceName: string
}

export const ConvertedImages = ({ deviceName }: ConvertedImageProps) => {
  const { images } = useContext(WebSocketContext)
  const [enlargedImageId, setEnlargedImageId] = useState<number | undefined>(undefined)

  const onImageClick = (id: number) => {
    setEnlargedImageId(id)
  }
  const deviceImages = images.get(deviceName)
  return (
    <JustifiedFlex>
      {deviceImages ? (
        [...deviceImages].sort().map((cameraIdxImageDataPair) => {
          const cameraIdx = cameraIdxImageDataPair[0]
          const imageData = cameraIdxImageDataPair[1]
          return (
            <>
              <JustifiedColumn>
                <FrontendImage
                  key={cameraIdx}
                  width="100px"
                  height="70px"
                  src={imageData}
                  alt={`cam ${cameraIdx + 1}`}
                  onClick={() => onImageClick(cameraIdx)}
                />
                <CenterP>cam {cameraIdx + 1}</CenterP>
              </JustifiedColumn>
              {enlargedImageId === cameraIdx ? (
                <EnlargedImages
                  open={enlargedImageId === cameraIdx}
                  {...{ imageData, cameraIdx, setEnlargedImageId }}
                />
              ) : (
                <></>
              )}
            </>
          )
        })
      ) : (
        <></>
      )}
    </JustifiedFlex>
  )
}
