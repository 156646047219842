import { ArrowDropDown, ArrowDropUp, CameraAlt } from "@mui/icons-material"
import NoPhotographyIcon from "@mui/icons-material/NoPhotography"
import { RiUnpinFill, RiPushpinFill } from "react-icons/ri"
import { Hide, Show } from "../../variables/sharedVariables"
import {
  ShowButtonDiv,
  ShowButton,
  ToggleButtonDiv,
  ButtonDiv,
} from "../../styles/FlashStationStyle/InProgressStyle"
import { CreateAxiosConfig } from "pages/AxiosConfig"
import { useEffect, useState } from "react"
import { ConfirmationDialogBoxForAbort } from "Components/Bootstrapping/ConfirmationForAbort"
import { ButtonText } from "utility/types"
import { useRedirect } from "react-admin"
import { CircularProgress, Snackbar } from "@mui/material"
import { logOut } from "utility/user_authentication"
import { CameraButton, PinButton } from "styles/DetailViewStyle"
import axios, { AxiosError, AxiosResponse } from "axios"

interface ToggleProp {
  id: string
  toggleDetailsButtonId: string
  setToggleDetailsButtonId: React.Dispatch<React.SetStateAction<string>>
  toggleCameraImagesId: string
  setToggleCameraImagesId: React.Dispatch<React.SetStateAction<string>>
  isDeviceActive?: boolean
  ifBootstrapPage: boolean
  isDeviceAborted?: boolean
  isOldReleaseVersion?: boolean
  pinAndUnPinDevice?: () => void
  pinnedIds?: string[]
  assuranceStateCompleted?: boolean
}
export const ToggleDetailView = ({
  id,
  toggleDetailsButtonId,
  setToggleDetailsButtonId,
  toggleCameraImagesId,
  setToggleCameraImagesId,
  isDeviceActive,
  ifBootstrapPage,
  isDeviceAborted,
  isOldReleaseVersion,
  pinAndUnPinDevice,
  pinnedIds,
  assuranceStateCompleted,
}: ToggleProp) => {
  const disableButton = !isDeviceActive && ifBootstrapPage
  const axiosAlphasenseFactory = CreateAxiosConfig("/api/v1")
  const [openAbortConfirmation, setOpenAbortConfirmation] = useState(false)
  const buttonText = isDeviceAborted ? ButtonText.start : ButtonText.abort
  const [loading, setLoading] = useState<string | null>(null)
  const [isNotFound, setIsNotFound] = useState(false)
  const canStreamSensorData = localStorage.getItem("can_stream_sensor_data") === "true"
  const redirect = useRedirect()

  const toggleDetails = () => {
    setToggleDetailsButtonId((prev) => {
      return prev === id ? "" : id
    })
  }

  const toggleImages = () => {
    setToggleCameraImagesId((prev) => {
      return prev === id ? "" : id
    })
  }

  const postStartOrAbort = (path: string) => {
    axiosAlphasenseFactory
      .post(`/devices/${id}/task_tree/${path}`, {})
      .then((response: AxiosResponse) => {
        if (response.status === 202) {
          setLoading(id)
        }
      })
      .catch((e: Error | AxiosError) => {
        if (axios.isAxiosError(e)) {
          if (e.response?.status === 401) {
            logOut(redirect)
          } else if (e.response?.status === 404) {
            setIsNotFound(true)
          } else {
            console.error(`Failed to ${path}`, e)
          }
        } else {
          console.error("Unexpected error:", e)
        }
      })
  }

  const buttonClick = () => {
    if (buttonText === ButtonText.abort) {
      setOpenAbortConfirmation(true)
    } else {
      if (buttonText === ButtonText.start) {
        postStartOrAbort("start")
      }
    }
  }

  useEffect(() => {
    if (id !== loading && loading !== null) {
      setLoading(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const handleConfirmationClose = () => {
    setOpenAbortConfirmation(false)
    postStartOrAbort(buttonText.toLowerCase())
  }
  const isPinned = pinnedIds && pinnedIds.includes(id)
  const disabledCondition =
    disableButton || isOldReleaseVersion || (assuranceStateCompleted && isPinned)
  return (
    <ToggleButtonDiv>
      <ButtonDiv style={{ marginTop: ifBootstrapPage ? "-30px" : "" }}>
        {ifBootstrapPage ? (
          <>
            <ShowButton
              disabled={disabledCondition}
              style={{
                marginBottom: "10px",
                background: disabledCondition ? "rgba(120, 120, 120, 0.6)" : "",
                cursor: disabledCondition ? "not-allowed" : "pointer",
              }}
              onClick={buttonClick}
            >
              {loading ? <CircularProgress style={{ color: "white" }} size="1rem" /> : buttonText}
            </ShowButton>
            <div style={{ display: "flex", flexDirection: "row", width: "155px" }}>
              {canStreamSensorData && (
                <CameraButton
                  disabled={!isDeviceActive}
                  style={{
                    marginBottom: "10px",
                    background: isDeviceActive ? "" : "rgba(120, 120, 120, 0.6)",
                    cursor: isDeviceActive ? "pointer" : "not-allowed",
                  }}
                  onClick={toggleImages}
                >
                  {toggleCameraImagesId === id ? <NoPhotographyIcon /> : <CameraAlt />}
                </CameraButton>
              )}
              <PinButton
                style={{ marginBottom: "10px", cursor: "pointer" }}
                onClick={pinAndUnPinDevice}
              >
                {isPinned ? <RiUnpinFill size={22} /> : <RiPushpinFill size={22} />}
              </PinButton>
            </div>
          </>
        ) : (
          <></>
        )}
        <ShowButton
          key={id}
          onClick={toggleDetails}
          style={{
            marginTop: !ifBootstrapPage ? "10px" : "",
            cursor: "pointer",
          }}
        >
          <ShowButtonDiv>
            {toggleDetailsButtonId !== id ? (
              <>
                {Show}
                <ArrowDropDown />
              </>
            ) : (
              <>
                {Hide}
                <ArrowDropUp />
              </>
            )}
          </ShowButtonDiv>
        </ShowButton>
      </ButtonDiv>
      {openAbortConfirmation && buttonText === ButtonText.abort && (
        <ConfirmationDialogBoxForAbort
          open={openAbortConfirmation}
          {...{ setOpenAbortConfirmation }}
          onClose={handleConfirmationClose}
        />
      )}
      {isNotFound ? (
        <Snackbar
          open={isNotFound}
          autoHideDuration={5000}
          message={`Failed to ${buttonText} for ${id}`}
          onClose={() => setIsNotFound(false)}
        />
      ) : (
        <></>
      )}
    </ToggleButtonDiv>
  )
}
