import { SubContainer } from "styles/CommonStyles"
import { Title } from "styles/FlashStationStyle/ReadyToFlashStyle"
import { InProgressTitle } from "variables/sharedVariables"
import { DeviceWithTransitions, SessionType } from "utility/types"
import { useDevicesWithTransitions } from "utility/useDevicesWithTransitions"

export type Filter = (input: DeviceWithTransitions[]) => DeviceWithTransitions[]

export interface BootstrappingDataProps {
  bootstrappingDevices: DeviceWithTransitions[]
  activeFilters: Map<string, Filter>
  pinnedIds: string[]
  setPinnedIds: React.Dispatch<React.SetStateAction<string[]>>
  pinnedDevicesUpdateTime: number
  setPinnedDevicesUpdateTime: React.Dispatch<React.SetStateAction<number>>
  onChangeRobotIdentity?: () => void
}

export const BootstrappingInProgressSession = ({
  bootstrappingDevices,
  activeFilters,
  pinnedIds,
  setPinnedIds,
  pinnedDevicesUpdateTime,
  setPinnedDevicesUpdateTime,
  onChangeRobotIdentity,
}: BootstrappingDataProps) => {
  const session: SessionType = SessionType.inProgressSession
  const devicesWithTransitions = useDevicesWithTransitions(
    bootstrappingDevices,
    true,
    session,
    activeFilters,
    pinnedIds,
    setPinnedIds,
    pinnedDevicesUpdateTime,
    setPinnedDevicesUpdateTime,
    onChangeRobotIdentity,
  )

  return (
    <SubContainer>
      <Title>{InProgressTitle}</Title>
      {devicesWithTransitions}
    </SubContainer>
  )
}
