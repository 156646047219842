import { BoldPre } from "styles/FlashStationStyle/InProgressStyle"
import {
  startingExecutionRegex,
  assuranceRegexStarted,
  calibrationRegexStarted,
  calibrationTransitionStart,
  configurationRegexStarted,
  includesString,
  includesRebootString,
  completedAssuranceStateRegex,
} from "./regex"
import {
  CalibrationSubStates,
  flashingDescriptionTranslations,
  stateTranslations,
} from "./translations"
import {
  BackendTransition,
  BootstrappingTransition,
  DeviceInfo,
  DeviceStatus,
  DeviceWithFrontendTransitions,
  DeviceWithTransitions,
  EState,
  FrontendTransition,
  SessionType,
  Transition,
  Translations,
} from "./types"
import { DeviceMonitoringState, Topic } from "./getDeviceState"
import { CreatedNotification } from "./factoryResultTypes"

export const translateState = (estate: any) => {
  if (estate === EState.IDENTIFYING_DEVICE) {
    return stateTranslations.installingOS
  } else if (estate === EState.INSTALLING) {
    return stateTranslations.installingSevensenseSoftware
  } else {
    return estate
  }
}

export const translateFlashingDescription = (description: EState) => {
  switch (description) {
    case EState.READY_FOR_FLASHING:
      return flashingDescriptionTranslations.readyForFlashing
    case EState.WAITING_FOR_FLASHING:
      return flashingDescriptionTranslations.waitingForFlashing
    case EState.FLASHING:
      return flashingDescriptionTranslations.flashing
    case EState.IDENTIFYING_DEVICE:
      return flashingDescriptionTranslations.installingOS
    case EState.INSTALLING:
      return flashingDescriptionTranslations.installingSevensenseSoftware
    case EState.COMPLETED:
      return flashingDescriptionTranslations.completed
    case EState.FAILED:
      return flashingDescriptionTranslations.failed
    default:
      return
  }
}

export const splitDeviceName = (deviceName: string): JSX.Element | JSX.Element[] => {
  if (deviceName.length > 10) {
    return deviceName.split("-").map((name, ix) => <BoldPre key={ix}>{name}</BoldPre>)
  }

  return <BoldPre>{deviceName}</BoldPre>
}

export const deviceName = (device: DeviceWithFrontendTransitions): JSX.Element | JSX.Element[] =>
  device.robot_serial_no ? (
    <BoldPre title={device.hostname}>{device.robot_serial_no}</BoldPre>
  ) : (
    <BoldPre>{device.hostname}</BoldPre>
  )

export const currentState = (state: string) => {
  return state
}

export const lastIndexOfTransitions = (transition: BootstrappingTransition[]) => {
  const lastIndex = transition.map((item) => item.message).lastIndexOf("")
  return lastIndex
}

export const lastIndexOfCalibrationTransitions = (transition: BootstrappingTransition[]) => {
  const lastIndex = transition
    .map((item) => item.message)
    .lastIndexOf("AstiSemiAutomaticCalibration")
  return lastIndex
}

export const slicedTransitions = (transitions: BackendTransition[]): BackendTransition[] => {
  const transitionFromEmptyMessage = lastIndexOfTransitions(transitions)
  const transitionsAfterFilter = transitions.slice(transitionFromEmptyMessage + 1)
  return transitionsAfterFilter
}

export const updateCurrentState = (
  currentState: EState,
  backendTransition: BackendTransition,
  isBootstrappingActive: boolean,
) => {
  if (configurationRegexStarted.test(backendTransition.message)) {
    return EState.CONFIGURATION
  } else if (calibrationRegexStarted.test(backendTransition.message)) {
    return EState.CALIBRATION
  } else if (assuranceRegexStarted.test(backendTransition.message)) {
    return EState.ACCEPTANCE
  } else if (
    completedAssuranceStateRegex.test(backendTransition.message) &&
    !isBootstrappingActive
  ) {
    return EState.COMPLETED
  }
  //We never have to deal with completed devices for bootstrapping as the highlevel state
  //already reflects that and they get filtered out
  return currentState
}

const mapCalibrationBackendTransitionsToFrontendTransitions = (
  calibrationTransitions: BackendTransition[],
): FrontendTransition[] => {
  return calibrationTransitions.map((calibrationTransition) => {
    const calibrationSubState =
      calibrationTransition.message === "Auto Start By Bootstrap"
        ? CalibrationSubStates.AutoStartByBootstrap
        : CalibrationSubStates[calibrationTransition.message]
    return {
      state: EState.CALIBRATION,
      substate: calibrationSubState,
      status: calibrationTransition.severity === 0 ? "Success" : "Failed",
      timestamp: calibrationTransition.timestamp,
    }
  })
}

const regExpExecutionOfScript = new RegExp(
  '^Execution of script "(?<scriptname>.*)" completed with exit code 0.$',
  "g",
)
export const translateBackendTransitionToFrontendTransition = (
  currentState: EState,
  backendTransition: BackendTransition,
  isLastTransition: boolean,
): FrontendTransition | undefined => {
  const backendTransitionMessage = backendTransition.message
  const excludeStartingOfExecution = startingExecutionRegex.test(backendTransitionMessage)
  const includesReboot = includesRebootString.test(backendTransitionMessage)

  if (
    ((excludeStartingOfExecution && !includesReboot) ||
      includesString.test(backendTransitionMessage)) &&
    !isLastTransition
  ) {
    return
  }

  const containsExecutionOfScript = regExpExecutionOfScript.exec(backendTransitionMessage)
  const replacedMessage = containsExecutionOfScript
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      `${containsExecutionOfScript.groups!["scriptname"]} completed successfully`
    : backendTransitionMessage

  return {
    state: currentState,
    substate: replacedMessage,
    status: backendTransition.severity === 0 ? "Success" : "Failed",
    timestamp: backendTransition.timestamp,
  }
}

export const mapBackendTransitionsToFrontendTransitions = (
  isBootstrappingActive: boolean,
  filteredTransitions: BackendTransition[],
  calibrationTransitions: BackendTransition[],
): FrontendTransition[] => {
  let currentState = EState.UNKNOWN
  const transitionsFromFrontend: FrontendTransition[] = []

  for (const [index, backendTransition] of filteredTransitions.entries()) {
    currentState = updateCurrentState(currentState, backendTransition, isBootstrappingActive)

    const calibrationTransitionStarted = calibrationTransitionStart.test(backendTransition.message)
    const filteredCalibrationTransitions = calibrationTransitions.filter(
      (transition) => Date.parse(transition.timestamp) > Date.parse(backendTransition.timestamp),
    )
    if (currentState === EState.CALIBRATION && calibrationTransitionStarted) {
      transitionsFromFrontend.push(
        ...mapCalibrationBackendTransitionsToFrontendTransitions(filteredCalibrationTransitions),
      )
    } else {
      const optionalNewTransition = translateBackendTransitionToFrontendTransition(
        currentState,
        backendTransition,
        index === filteredTransitions.length - 1,
      )
      if (optionalNewTransition) {
        transitionsFromFrontend.push(optionalNewTransition)
      }
    }
  }

  const transitionsAfterRemovingDuplicates = transitionsFromFrontend.filter(
    (transition, idx, arr) => {
      return idx === 0 || transition.substate !== arr[idx - 1].substate
    },
  )

  return transitionsAfterRemovingDuplicates
}

export const flashingStationTransitionsToFrontendTransitions = (
  flashingStationTransitions: Transition[],
): FrontendTransition[] => {
  return flashingStationTransitions.map((transition, index) => {
    return {
      state: translateState(EState[transition.new_state]),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      substate: translateFlashingDescription(EState[transition.new_state])!,
      status:
        index === flashingStationTransitions.length - 1 &&
        !["FAILED", "COMPLETED"].includes(transition.new_state)
          ? EState.INPROGRESS
          : transition.new_state === "FAILED"
            ? EState.FAILED
            : transition.new_state === "COMPLETED"
              ? EState.COMPLETED
              : EState.SUCCESS,
      timestamp: transition.timestamp,
    }
  })
}

export const translateReport = (key: string, reportTranslations: Translations) => {
  if (Object.prototype.hasOwnProperty.call(reportTranslations, key)) {
    return reportTranslations[key]
  }
  return key
}

export const getDeviceStatus = (
  deviceInfo: DeviceWithTransitions,
  deviceMonitoringStates: Map<Topic, Map<string, DeviceMonitoringState>>,
  deviceState: EState,
): DeviceStatus => {
  //No transitions means not started (but realistically should not get here)
  if (deviceInfo.transitions.length === 0) {
    return DeviceStatus.NotStarted
  }
  const lastTransition = deviceInfo.transitions[deviceInfo.transitions.length - 1]
  if (lastTransition.severity >= 2) {
    return DeviceStatus.BootstrapFailed
  }
  //TODO maybe we have to relax the requirement for the number eventually, but for now thats more accurate.
  const manualCheckRegex = /.*95-manual-inspection.*/
  if (manualCheckRegex.test(lastTransition.message)) {
    return DeviceStatus.ManualCheck
  }

  if (deviceState === EState.CALIBRATION) {
    const calibServerState = deviceMonitoringStates
      .get("calibration_server_error")
      ?.get(deviceInfo.hostname)
    if (calibServerState) {
      if (calibServerState.severity >= 2) {
        return DeviceStatus.CalibrationFailed
      }
      if (calibServerState.severity === 1) {
        return DeviceStatus.CalibrationSanityCheckFailed
      }
    }
  }
  return DeviceStatus.Ok
}

export const getSessionForDevice = (
  deviceStatus: DeviceStatus,
  isAborted: boolean,
  currentSubstate?: string,
  pinnedDevice?: boolean,
): SessionType => {
  const actionRequiredConditions =
    deviceStatus !== DeviceStatus.Ok ||
    isAborted ||
    currentSubstate === "Please move the robot into the Calibration Arena"
  if (pinnedDevice) {
    return SessionType.pinnedSession
  } else {
    if (deviceStatus === DeviceStatus.NotStarted) {
      return SessionType.unknown
    } else if (actionRequiredConditions) {
      return SessionType.actionRequiredSession
    }
    return SessionType.inProgressSession
  }
}

export const isReleaseVersionOld = (version: string | undefined): boolean => {
  if (version?.includes("release~2")) {
    return true
  }
  return false
}

export const deviceInfoFromHostname = (hostname: string): DeviceInfo => {
  const parts = hostname.split("-")
  if (parts.length < 2) {
    throw new Error("Invalid hostname")
  }
  const id = parts.pop() as string
  const type = parts.join("-")

  return {
    type: type,
    id: id,
    hostname,
  }
}

export const formatNotification = (notification: CreatedNotification): string => {
  if (!notification.metadata) {
    return notification.message
  }

  let str = notification.message
  for (const [key, value] of Object.entries(notification.metadata)) {
    str = str.replace(`{${key}}`, value)
  }

  return str
}
