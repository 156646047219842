import { Container } from "@mui/material"
import { styled } from "@mui/material/styles"
import { styled as styles } from "styled-components"

export const CommonDivContainer = styles.div`
  width: 100vw;
`
export const MainDiv = styles.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const CommonSessionContainer = styles.div`
  width: 90vw;
  @media (min-width: 360px) {
    margin: 10px 0;
  }
  @media (min-width: 768px) {
    margin: 20px 0;
  }
`
export const CommonTitleDiv = styles(CommonSessionContainer)`
  margin-bottom: 10px !important;
`

export const MainTitle = styles.h2`
  align-self: flex-start;
  margin-left: -25px;
  color: #e44411;
  @media (min-width: 360px) {
    text-align: left;
  }
`
export const SubContainer = styled(Container)`
  background-color: rgba(217, 217, 217, 0.5);
  border-radius: 5px;
  padding-bottom: 30px;

  @media (min-width: 360px) {
    text-align: center;
    padding: 10px 30px 30px;
  }
  @media (min-width: 768px) {
    text-align: left;
  }
`
export const DeviceNameDiv = styles.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  min-width: 15%;
  padding-left: 10px;
  @media (max-width: 768px) {
    padding-left: 0px;
  }
`
export const IconTextDiv = styles.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
  padding-left: 10px;
  color: #2F2F2F;
  span {
    color: #595959;
  }
`
export const ProgressBarDiv = styles.div`
  width: 70%;
  @media (max-width: 768px) {
    width: 100%;
  }
`
export const ToggleButtonsDiv = styles.div`
  min-width: 15%;
  @media (max-width: 768px) {
    padding-left: 0px !important;
  }
`
