import { styled } from "@mui/material/styles"
import styles from "styled-components"
import { Card } from "@mui/material"

export const LoginFormContainer = styled(Card)`
  min-height: 263px;
  width: 100%;
  display: grid;
`
export const LoginFormDiv = styles.form`
  height: 100%;
  width: 100%;
  display: grid;
  gap: 5px;
  justify-items: center;
  align-items: center;
  @media (min-width: 768px) {
  }

  @media (min-width: 1280px) {
    padding-top: 30px;
  }
`
export const LoginFormTitle = styles.h2``
export const FieldWrapper = styles.div`
  padding: 0 15px;
  width: 100%;

  @media screen and (min-width: 600px) {
    padding: 0 50px;
    width: 100%;
  }
`
export const Field = styles.div`
  display: grid;
  gap: 5px;
  padding: 20px auto;
`
export const Label = styles.label`
  height: 16px;
  max-width: 160px;
  color: #303544;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
`
export const InputWrapper = styles.div`
  position: relative;
  padding-bottom: 10px;
  display: grid;
`
export const Input = styles.input`
  width: 100%;
  height: 50px;
  min-height: 34px;
  border-radius: 4px;
  border-color: transparent;
  background-color: #eaedf3;
  padding: 10px;
  margin: 10px 0;
  padding-right: 40px;

  &:focus {
    outline: none;
  }

  &.error {
    background-color: #eaedf3;
    box-shadow: inset 0 -2px 0 0 #ff0000;
    border: none;
  }
`
export const IconWrapper = styles.span`
  position: absolute;
  right: 1px;
  bottom: 20px;
  padding: 8px 10px;
  border-radius: 2px;
  color: gray;
`
export const LoginButton = styles.button`
  background-color: #e44411;
  color: #fff;
  width: 100%;
  height: 60px;
  cursor: pointer;
  border-color: transparent;
  margin-top: 40px;
  font-size: 16px;

  &.disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`
export const Icon = styles.img`
  margin: 50px 0 100px 0;
`
export const SuccessMessage = styles.h3`
  margin-bottom: 50px;
`
export const ErrorMessage = styles.span`
  font-size: 14px;
  color: #ff0000;
  margin-top: -10px;
  margin-bottom: 15px;
`
