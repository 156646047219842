import logo from "../../assets/images/logo.svg"
import {
  Logo,
  LogoTitleDiv,
  StyledGridItem,
  SubTitle,
  Content,
} from "../../styles/LoginStyle/LoginStyles"
import { MainTitle, WelcomeNote } from "../../variables/sharedVariables"

export const LogoContent = () => {
  return (
    <StyledGridItem item xs={12}>
      <LogoTitleDiv>
        <Logo src={logo} alt="" />
      </LogoTitleDiv>
      <SubTitle>{MainTitle}</SubTitle>
      <Content>{WelcomeNote}</Content>
    </StyledGridItem>
  )
}
