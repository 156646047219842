import { makeStyles } from "@mui/styles"
import { Dialog, IconButton, Paper } from "@mui/material"
import { FactoryResultPage } from "./FactoryResultPage"
import CloseIcon from "assets/icons/CloseIcon.svg"
import { ColumnWithoutJustified } from "styles/ProgressReportStyles"
import { DeviceInfo } from "utility/types"
import { FactoryResultWithMetadata } from "utility/factoryResultTypes"

export interface DialogProps {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  reportDetails?: FactoryResultWithMetadata
  device: DeviceInfo
}

const customStyles: any = makeStyles(() => ({
  dialogContainer: {
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
    maxHeight: "100%",
    maxWidth: "100%",
    background: "white",
  },
}))

export const ReportPage = ({ open, setOpen, reportDetails, device }: DialogProps) => {
  const classes = customStyles()
  const clickClose = () => {
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={clickClose}>
      <Paper className={classes.dialogContainer}>
        <ColumnWithoutJustified>
          <IconButton
            aria-label="close"
            onClick={clickClose}
            style={{ alignSelf: "self-end", marginTop: "10px", marginRight: "10px" }}
          >
            <img src={CloseIcon} alt="Close" />
          </IconButton>
          <div>{reportDetails && <FactoryResultPage result={reportDetails} device={device} />}</div>
        </ColumnWithoutJustified>
      </Paper>
    </Dialog>
  )
}
