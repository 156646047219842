import { useRedirect } from "react-admin"
import { useForm } from "react-hook-form"
import { ChangePassword, ConfirmPassword, NewPassword, Save } from "../../variables/sharedVariables"
import {
  Field,
  FieldWrapper,
  Input,
  InputWrapper,
  Label,
  LoginButton,
  LoginFormContainer,
  LoginFormDiv,
  LoginFormTitle,
} from "../../styles/LoginStyle/LoginFormStyle"

export const ChangePasswordForm = () => {
  const redirect = useRedirect()
  const { register, handleSubmit } = useForm()
  const handleClick = () => redirect("/confirm_password")

  return (
    <LoginFormContainer>
      <LoginFormDiv onSubmit={handleSubmit(handleClick)}>
        <LoginFormTitle>{ChangePassword}</LoginFormTitle>
        <FieldWrapper>
          <Field>
            <Label htmlFor="newPassword">{NewPassword}</Label>
            <InputWrapper>
              <Input {...register("newPassword", { required: true })} />
            </InputWrapper>
            <Label htmlFor="confirmPassword">{ConfirmPassword}</Label>
            <InputWrapper>
              <Input {...register("confirmPassword", { required: true })} />
            </InputWrapper>
          </Field>
        </FieldWrapper>
        <LoginButton>{Save}</LoginButton>
      </LoginFormDiv>
    </LoginFormContainer>
  )
}
