import { useState } from "react"
import { useRedirect } from "react-admin"
import { useForm } from "react-hook-form"
import { Visibility, VisibilityOff, Email } from "@mui/icons-material"
import {
  LoginFormContainer,
  LoginFormDiv,
  LoginFormTitle,
  FieldWrapper,
  Field,
  Label,
  InputWrapper,
  Input,
  LoginButton,
  IconWrapper,
  ErrorMessage,
} from "../../styles/LoginStyle/LoginFormStyle"
import { Login, Password, Username, AuthError } from "../../variables/sharedVariables"
import axios, { AxiosResponse } from "axios"
import { getUserWithPinnedDevices } from "utility/localStorage"

interface LoginData {
  username: string
  password: string
}
interface Permissions {
  [key: string]: boolean
}
export const LoginForm = () => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [authError, setAuthError] = useState(false)
  const redirect = useRedirect()
  const toggleVisibility = () => setPasswordVisible(!passwordVisible)

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
  } = useForm<LoginData>()

  function catchLoginError() {
    setAuthError(true)
    setError("username", {
      type: "manual",
    })
    setError("password", {
      type: "manual",
    })
  }

  const login = (data: LoginData) => {
    const params = {
      username: data.username,
      password: data.password,
    }

    axios
      .post("/api/v1/login", params)
      .then((response: AxiosResponse) => {
        if (response.status === 200) {
          const permissions: Permissions = response.data.permissions
          localStorage.setItem("token", response.data.bearer_token)
          localStorage.setItem("username", params.username)
          Object.entries(permissions).map(([key, value]) =>
            localStorage.setItem(`${key}`, value.toString()),
          )
          const userName = getUserWithPinnedDevices()
          if (params.username !== userName?.userName) {
            localStorage.removeItem("userWithPinnedIds")
          }
          if (permissions.can_view_flashing_station) {
            redirect("/flashingstation")
          } else {
            redirect("/bootstrapping")
          }
        } else {
          setAuthError(true)
        }
      })
      .catch(catchLoginError)
  }

  return (
    <LoginFormContainer elevation={9}>
      <LoginFormDiv onSubmit={handleSubmit(login)}>
        <LoginFormTitle>{Login}</LoginFormTitle>
        <FieldWrapper>
          <Field>
            <Label htmlFor="username">{Username}</Label>
            <InputWrapper>
              <Input
                {...register("username", { required: true })}
                className={errors.username || errors.password ? "error" : ""}
              />
              <IconWrapper>
                <Email />
              </IconWrapper>
            </InputWrapper>
            <Label>{Password}</Label>
            <InputWrapper>
              <Input
                {...register("password", { required: true })}
                type={passwordVisible ? "text" : "password"}
                className={errors.username || errors.password ? "error" : ""}
              />
              <IconWrapper>
                {passwordVisible ? (
                  <VisibilityOff onClick={toggleVisibility} />
                ) : (
                  <Visibility onClick={toggleVisibility} />
                )}
              </IconWrapper>
            </InputWrapper>
          </Field>
          {authError ? <ErrorMessage>{<span>{AuthError}</span>}</ErrorMessage> : ""}
        </FieldWrapper>
        <LoginButton
          disabled={authError && !isValid}
          className={authError && !isValid ? "disabled" : ""}
        >
          {Login}
        </LoginButton>
      </LoginFormDiv>
    </LoginFormContainer>
  )
}
