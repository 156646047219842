import { Button, Dialog, DialogActions, DialogContentText, DialogTitle } from "@mui/material"
import { CreateAxiosConfig } from "pages/AxiosConfig"
import { useStyles } from "pages/ErrorDialogBox/ErrorNotification"
import { Cancel } from "variables/sharedVariables"

interface ConfirmationProps {
  openDialogBox: boolean
  setOpenDialogBox: React.Dispatch<React.SetStateAction<boolean>>
  onConfirm: () => void
  title: string
  message: string
  buttonText: string
}
export const callClearAllDevicesEndpoint = (url: string, notify: (message: string) => void) => {
  const axiosAlphasenseFactory = CreateAxiosConfig("/api/v1")
  axiosAlphasenseFactory.delete(`${url}`).catch(() => notify("Failed to delete devices"))
}
export const ConfirmationDialogBox = ({
  openDialogBox,
  setOpenDialogBox,
  onConfirm,
  title,
  message,
  buttonText,
}: ConfirmationProps) => {
  const rootStyle = useStyles()

  const handleOnClose = (_event: any, reason: "backdropClick" | "escapeKeyDown") => {
    // In Material UI v5 the Dialog's property 'disableBackdropClick' is deprecated.
    // We need to manually check for the backdropClick in the Dialog's onClose function.
    if (reason && reason === "backdropClick") {
      return
    }
    setOpenDialogBox(false)
  }

  return (
    <Dialog open={openDialogBox} onClose={handleOnClose} classes={{ root: rootStyle.root }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContentText id="alert-dialog-slide-description">{message}</DialogContentText>
      <DialogActions>
        <Button onClick={() => setOpenDialogBox(false)}>{Cancel}</Button>
        <Button
          onClick={() => {
            onConfirm()
            setOpenDialogBox(false)
          }}
        >
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
