import { Dialog, IconButton } from "@mui/material"
import closeIcon from "assets/icons/CloseIcon.svg"
import { ImageBackground } from "styles/ProgressReportStyles"

interface ImageProps {
  open: boolean
  imageData: string
  cameraIdx: number
  setEnlargedImageId: React.Dispatch<React.SetStateAction<number | undefined>>
}
export const EnlargedImages = ({ open, imageData, cameraIdx, setEnlargedImageId }: ImageProps) => {
  return (
    <Dialog open={open}>
      <ImageBackground>
        <IconButton aria-label="close" style={{ alignSelf: "self-end" }}>
          <img src={closeIcon} alt="close" onClick={() => setEnlargedImageId(undefined)} />
        </IconButton>
        <img src={imageData} alt={`cam ${cameraIdx + 1}`} />
      </ImageBackground>
    </Dialog>
  )
}
