import { SubContainer } from "styles/CommonStyles"
import { Title } from "styles/FlashStationStyle/ReadyToFlashStyle"
import { BootstrappingDataProps } from "./InProgressSession"
import { useDevicesWithTransitions } from "utility/useDevicesWithTransitions"
import { SessionType } from "utility/types"

export const ActionRequiredSession = ({
  bootstrappingDevices,
  activeFilters,
  pinnedIds,
  setPinnedIds,
  pinnedDevicesUpdateTime,
  setPinnedDevicesUpdateTime,
  onChangeRobotIdentity,
}: BootstrappingDataProps) => {
  const session: SessionType = SessionType.actionRequiredSession
  const devicesWithTransitions = useDevicesWithTransitions(
    bootstrappingDevices,
    true,
    session,
    activeFilters,
    pinnedIds,
    setPinnedIds,
    pinnedDevicesUpdateTime,
    setPinnedDevicesUpdateTime,
    onChangeRobotIdentity,
  )

  return (
    <SubContainer>
      <Title>ACTION REQUIRED</Title>
      {devicesWithTransitions}
    </SubContainer>
  )
}
